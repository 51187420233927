import React, { Fragment, useEffect, useState } from 'react'
import api from '../../api';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import UserRegistrationForm from './AddUser';

function Users() {
    const [user, SetUser] = useState([]);
    const fetchUser = () => {
        api.get(`users/`).then((res) => {
            const users = res.data.result.users;
            SetUser(users);
        });
    };
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'title': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN }
    });

    useEffect(() => {
        fetchUser();
    }, []);

    const actionBodyTemplate = (rowData) => {
        //onClick={() => editProduct(rowData)}
        // onClick={() => confirmDeleteProduct(rowData)} 
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded className="mr-2 rounded-5" />
                <Button icon="pi pi-trash" rounded severity="danger" />
            </React.Fragment>
        );
    };
    const openNew = () => {
        // setProduct(emptyProduct);
        // setSubmitted(false);
        // setProductDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="New Users" icon="pi pi-plus" severity="success" onClick={handleCreate} />
                {/* <Button label="Delete" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
            </div>
        );
    };


    const [show, setShow] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [catid, SetCatid] = useState("");
    const handleClose = () => setShow(false);
    const handleEdit = (id) => {
        setShow(true);
        setEditMode(true);
        SetCatid(id);
        setCreateMode(false);
        setDeleteMode(false);
    };
    const handleDelete = (id) => {
        SetCatid(id);
        setShow(true);
        setDeleteMode(true);
        setCreateMode(false);
        setEditMode(false);
    };

    const handleCreate = () => {
        setShow(true);
        setCreateMode(true);
        setEditMode(false);
        setDeleteMode(false);
    };
    return (
        <Fragment>
            <Toolbar className="mb-4" start={leftToolbarTemplate}></Toolbar>
            <DataTable value={user} tableStyle={{ minWidth: '50rem' }} filters={filters}>
                <Column field="title" header="Utilisateur" sortable ></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
            </DataTable>


            {/*modal  */}
            {createMode && (
                <UserRegistrationForm
                    show={show}
                    handleClose={handleClose}
                    // reFresh={fetchService}
                />
            )}
            {/* {editMode && (
                <UpdateServices
                    show={show}
                    onClose={handleClose}
                    reFresh={fetchService}
                    catId={catid}
                />
            )} */}
            {/* {deleteMode && (
                <DeleteServices
                    show={show}
                    onClose={handleClose}
                    reFresh={fetchService}
                    catId={catid}
                />
            )} */}
        </Fragment>
    )
}

export default Users
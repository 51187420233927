// src/UserRegistrationForm.js
import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';

const UserRegistrationForm = ({ show, handleClose, reFresh }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password || !name || !role) {
      setError('Veuillez remplir tous les champs.');
    } else {
      setError('');
      // Logique de création de compte utilisateur (à implémenter)
      console.log('Email:', email);
      console.log('Nom:', name);
      console.log('Mot de passe:', password);
      console.log('Rôle:', role);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      {error && <p className="text-danger text-center">{error}</p>}
      <Form onSubmit={handleSubmit} >
        <Modal.Header closeButton>
          <Modal.Title>Créer un compte utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* Email */}
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Entrer l'email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          {/* Mot de passe généré dynamiquement */}
          {/* <Form.Group controlId="formPassword" className="mb-3">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              type="password"
              placeholder="Entrer le mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group> */}

          {/* Nom */}
          <Form.Group controlId="formName" className="mb-3">
            <Form.Label>Nom utilisateur</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrer le nom"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          {/* Rôle */}
          <Form.Group controlId="formRole" className="mb-3">
            <Form.Label>Rôle</Form.Label>
            <Form.Control
              as="select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
            >
              <option value="" disabled>Sélectionner un rôle</option>
              <option value="4">Admin</option>
              <option value="1">Utilisateur</option>
              <option value="2">Manager</option>
              <option value="3">Customer</option>
            </Form.Control>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          {/* Bouton de soumission */}
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Créer un compte
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>

  );
};

export default UserRegistrationForm;

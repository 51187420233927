import React, { Fragment, useEffect, useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import des styles par défaut
import Select from 'react-select';
import api from '../../api';
import toast, { Toaster } from 'react-hot-toast';



function Posts() {

    const notify = () => toast.success('Votre post a été enregistré avec succès!');
    const [service, SetService] = useState([]);

    const fetchService = () => {
        api.get(`services/getCombox`).then((res) => {
            const service = res.data.result.servics;
            SetService(service);
        });
    };


    useEffect(() => {
        fetchService();
    }, []);
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        status: 'draft',
        category: '',
    });
    const handleCategoryChange = (selectedOption) => {
        setFormData({
            ...formData,
            category: selectedOption.value
        });
    };
    // Gestion du changement de contenu pour React Quill
    const handleContentChange = (value) => {
        setFormData({
            ...formData,
            content: value
        });
    };
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Vous pouvez ici gérer l'envoi des données au serveur
        console.log('Post Submitted:', formData);
              api.post(`/Posts/add`, formData).then((res) => {
            if (res.data.status === "OK") {
                notify();
                setFormData({
                    title: '',
                    content: '',
                    status: 'draft',
                    category: '',
                })

            } else {
                // this.ErrorSwal();
            }
        });
    };
    return (
        <Fragment>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1 class="m-0">Posts</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">

                                        {/* <h5 class="card-title">Posts</h5>
                                        <p class="card-text">
                                            Some quick example text to build on the card title and make up the bulk of the card's
                                            content.
                                        </p> */}
                                        <Toaster />

                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group controlId="formTitle" className='mb-3'>
                                                <Form.Label>Titre</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="title"
                                                    placeholder="Entrez le titre du post"
                                                    value={formData.title}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formCategory" className='mb-3'>
                                                <Form.Label>Service</Form.Label>
                                                <Select
                                                    options={service}
                                                    onChange={handleCategoryChange}
                                                    placeholder="Sélectionnez une forme juridique..."
                                                    isSearchable
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formContent" className='mb-3'>
                                                <Form.Label>Contenu</Form.Label>
                                                {/* <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    name="content"
                                                    placeholder="Enter post content"
                                                    value={formData.content}
                                                    onChange={handleChange}
                                                    required
                                                /> */}
                                                <ReactQuill
                                                    theme="snow"
                                                    value={formData.content}
                                                    onChange={handleContentChange}
                                                    placeholder="Enter post content"
                                                />
                                            </Form.Group>
                                            {/* 
                                            <Form.Group controlId="formAuthor">
                                                <Form.Label>Author</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="author"
                                                    placeholder="Enter author name"
                                                    value={formData.author}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group> */}

                                            <Form.Group controlId="formStatus">
                                                <Form.Label>Statut</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="status"
                                                    value={formData.status}
                                                    onChange={handleChange}
                                                >
                                                    <option value="draft">Draft</option>
                                                    <option value="published">Published</option>
                                                    <option value="archived">Archived</option>
                                                </Form.Control>
                                            </Form.Group>



                                            {/* <Form.Group controlId="formSlug">
                                                <Form.Label>Slug</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="slug"
                                                    placeholder="Enter URL-friendly slug"
                                                    value={formData.slug}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group> */}

                                            <Button variant="primary" type="submit" className="mt-3">
                                                Valider
                                            </Button>
                                        </Form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Posts
import React, { Fragment, useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../data';
import Categories from './Categories';
import Services from './Services';
import Users from './Users';
import Configs from './Configs';
import Activity from './Activity';
import FaqForm from './FaqForm';


function Settings() {
  const [products, setProducts] = useState([{
    id: '1000',
    code: 'f230fh0g3',
    name: 'Bamboo Watch',
    description: 'Product Description',
    image: 'bamboo-watch.jpg',
    price: 65,
    category: 'Accessories',
    quantity: 24,
    inventoryStatus: 'INSTOCK',
    rating: 5
  },]);

  useEffect(() => {
    ProductService.getProductsMini().then(data => setProducts(data));
  }, []);
  return (
    <Fragment>
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Paramètre</h1>
              </div>

            </div>
          </div>
        </div>

        <div class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                {/* <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Paramètre</h5>

                  <p class="card-text">
                    Some quick example text to build on the card title and make up the bulk of the card's
                    content.
                  </p>


                </div>
              </div> */}
                <div class="card card-primary card-outline card-outline-tabs">
                  <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Categories</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Services</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill" href="#custom-tabs-four-messages" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false">Utilisateurs</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-four-settings-tab" data-toggle="pill" href="#custom-tabs-four-settings" role="tab" aria-controls="custom-tabs-four-settings" aria-selected="false">Role & Permission</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-four-secteurs-tab" data-toggle="pill" href="#custom-tabs-four-secteurs" role="tab" aria-controls="custom-tabs-four-secteurs" aria-selected="false">Secteurs d'Activités</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-four-secteurs-tab" data-toggle="pill" href="#custom-tabs-four-faq" role="tab" aria-controls="custom-tabs-four-faq" aria-selected="false">FAQ</a>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                      <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">

                        <div className="card border-0 shadow-none">
                          <Categories />
                        </div>
                      </div>
                      <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                        <Services />
                      </div>
                      <div class="tab-pane fade" id="custom-tabs-four-messages" role="tabpanel" aria-labelledby="custom-tabs-four-messages-tab">
                        <Users/>
                      </div>
                      <div class="tab-pane fade" id="custom-tabs-four-settings" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                        <Configs />  

                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-secteurs" role="tabpanel" aria-labelledby="custom-tabs-four-secteurs-tab">
                        <Activity />  
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-faq" role="tabpanel" aria-labelledby="custom-tabs-four-faq-tab">
                        <FaqForm />  
                        </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Settings
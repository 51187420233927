import React, { Fragment } from 'react'
import Navigation from './Navigation'
import SideBar from './SideBar'
import Footer from './Footer'
import Navbar from './Navbar'
import 'primeicons/primeicons.css';
import "primereact/resources/themes/tailwind-light/theme.css";

export default function App() {

  return (
    <Fragment>
        <Navbar />
        <SideBar />
        <Navigation />
        <Footer />
    </Fragment>
  )
}


import React, { Fragment, useEffect, useState } from 'react'
import api from '../../api';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
function Demande() {
    const [customerRequest, setCustomerRequest] = useState([]);

    const fetchCustomerRequest = () => {
        api.get(`customers/`).then((res) => {
            const customers = res.data.result.customers;
            setCustomerRequest(customers);
        });
    };

    useEffect(() => {
        fetchCustomerRequest();
    }, []);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'title': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN }
    });
    const actionBodyTemplate = (rowData) => {
        //onClick={() => editProduct(rowData)}
        // onClick={() => confirmDeleteProduct(rowData)} 
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded className="mr-2 rounded-5" />
                <Button icon="pi pi-trash" rounded severity="danger" />
            </React.Fragment>
        );
    };
    return (
        <Fragment>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1 class="m-0">Demande</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                      
                                        <DataTable value={customerRequest} size={'small'} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} filters={filters}>
                                            <Column field="lastname" header="Nom" sortable ></Column>
                                            <Column field="firstname" header="Prenoms" sortable ></Column>
                                            <Column field="email" header="Adresse email" sortable ></Column>
                                            <Column field="phone_no" header="Ténéphone" sortable ></Column>
                                            <Column field="secteur.title" header="Activité" sortable ></Column>
                                            <Column field="body" header="Message" sortable ></Column>
                                            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Demande
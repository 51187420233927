import React, { Fragment } from 'react'

function Configs() {
  return (
    <Fragment>
        <h1>Role & Permission</h1>
    </Fragment>
  )
}

export default Configs
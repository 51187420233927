import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import api from '../../api';
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Services enregistré avec succès !');

function CreateServices({ show, handleClose, reFresh }) {
    const [category, SetCategory] = useState([]);

    const fetchCategory = () => {
        api.get(`categories/index`).then((res) => {
            const category = res.data.result.categories;
            SetCategory(category);
        });
    };

    useEffect(() => {
        fetchCategory();
    }, []);

    const [form, setForm] = useState({
        title: "",
        categoryId: "",
        body: "",
    });

    const handleChange = (field, value) => {
        setForm((state) => ({ ...state, [field]: value }));
    };

    const resetAfterSave = () => {
        setForm({
            title: "",
            categoryId: "",
            body: "",
        });
    };

    const handleSave = (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append("title", form.title);
        data.append("category_id", form.categoryId);
        data.append("body", form.body);

        api.post(`/services/add`, data).then((res) => {
            if (res.data.status === "OK") {
                notify();
                console.log(res);
                resetAfterSave();
                reFresh();

            } else {
                // this.ErrorSwal();
            }
        });
    };
    return (
        <>
            <Toaster />
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nouveau services</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Titre du service</Form.Label>
                            <Form.Control
                                type="text"
                                autoFocus
                                value={form.title}
                                onChange={(event) =>
                                    handleChange("title", event.target.value)
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Categorie service</Form.Label>
                            <Form.Select aria-label="Default select example" className='form-control'
                                value={form.categoryId}
                                onChange={(event) =>
                                    handleChange("categoryId", event.target.value)
                                }>
                                <option selected disabled value="">
                                    Selectionnez...
                                </option>
                                {category.map((categorie, id) => (
                                    <option value={categorie.id} key={id}>
                                        {categorie.title}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description du service</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                value={form.body}
                                onChange={(event) =>
                                    handleChange("body", event.target.value)
                                }
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button variant="primary" type="submit">
                            Valider
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    )
}

export default CreateServices
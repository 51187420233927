import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Customers from './components/customers';
import Prospect from './components/prospect';
import Settings from './components/parametre';
import Demande from './components/demande';
import Posts from './components/posts';
import Message from './components/message';
import Documents from './components/document';
function Navigation() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/customer" element={<Customers />} />
            <Route path="/prospect" element={<Prospect />} />
            <Route path="/demande" element={<Demande />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/posts" element={<Posts />} />
            <Route path="/message" element={<Message />} />
            <Route path="/mes-documents" element={<Documents />} />
        </Routes>
    )
}

export default Navigation
import React, { Fragment } from 'react'

function Footer() {
  return (
   <Fragment>
  <footer class="main-footer">
    <div class="float-right d-none d-sm-inline">
      Anything you want
    </div>
    <strong>Copyright &copy; 2024 <a href="#">Yanily Compta V0.1</a>.</strong> All rights reserved.
  </footer>
   </Fragment>
  )
}

export default Footer
import React, { Fragment, useEffect, useState } from 'react'
import api from '../../api';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';

function Categories() {
    const [category, SetCategory] = useState([]);
    const fetchCategorie = () => {
        api.get(`categories/`).then((res) => {
            const categories = res.data.result.categories;
            //console.log('Categories', categories);
            SetCategory(categories);
        });
    };
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'title': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN }
    });

    useEffect(() => {
        fetchCategorie();
    }, []);

    const actionBodyTemplate = (rowData) => {
        //onClick={() => editProduct(rowData)}
        // onClick={() => confirmDeleteProduct(rowData)} 
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded  className="mr-2 rounded-5"  />
                <Button icon="pi pi-trash" rounded  severity="danger"/>
            </React.Fragment>
        );
    };
    const openNew = () => {
        // setProduct(emptyProduct);
        // setSubmitted(false);
        // setProductDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                {/* <Button label="Delete" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
            </div>
        );
    };
    return (
        <Fragment>
            <Toolbar className="mb-4" start={leftToolbarTemplate}></Toolbar>
            <DataTable value={category} tableStyle={{ minWidth: '50rem' }} filters={filters}>
                <Column field="title" header="Categorie service" sortable ></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
            </DataTable>
        </Fragment>
    )
}

export default Categories
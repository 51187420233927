import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

function SideBar() {
  return (
    <Fragment>

      <aside class="main-sidebar sidebar-dark-primary elevation-4">

        <a href="#" class="brand-link">
          {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"/> */}
          <span class="brand-text font-weight-bold">Yanily Compta V.02</span>
        </a>


        <div class="sidebar">

          <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
              {/* <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image" /> */}
            </div>
            <div class="info">
              <a href="#" class="d-block">Franck Loami</a>
            </div>
          </div>



          <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

              <li class="nav-item menu-open">
                <Link to="/" className="nav-link">
                  <i class="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                    Tableau de Bord
                  </p>
                </Link>

              </li>
              <li class="nav-item">
                <Link to="/posts  " className="nav-link">
                  <i class="nav-icon fas fa-newspaper"></i>
                  <p>
                    Post
                  </p>
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/demande" className="nav-link">
                  <i class="nav-icon fas fa-tasks"></i>
                  <p>
                    Demande service
                  </p>
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/customer" className="nav-link">
                  <i class="nav-icon fas fa-address-book"></i>
                  <p>
                    Client
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/prospect" className="nav-link">
                  <i class="nav-icon  fas fa-users"></i>
                  <p>Prospect</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/mes-documents" className="nav-link">
                  <i class="nav-icon  fas fa-book"></i>
                  <p>Documents</p>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/message" className="nav-link">
                  <i class="nav-icon  fas fa-envelope"></i>
                  <p>Message</p>
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="/settings" className="nav-link">
                  <i class="nav-icon  fas fa-cogs"></i>
                  <p>Paramètres</p>
                </Link>
              </li>
            </ul>
          </nav>

        </div>

      </aside>

    </Fragment>
  )
}

export default SideBar
import React, { Fragment } from 'react'

function Message() {
  return (
    <Fragment>
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Message</h1>
              </div>

            </div>
          </div>
        </div>

        <div class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Messages</h5>

                    <p class="card-text">
                      Some quick example text to build on the card title and make up the bulk of the card's
                      content.
                    </p>


                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Message